const dic = {
    "Chapter": "Chapter",
    "Psalm": "Psalm",
    "Ukrainian": "Ukrainian",
    "Russian": "Russian",
    "English": "English",
    "Compare": "Compare",
    "Gallery": "Gallery",
    "History of reading": "History of reading",
    "Bible lessons": "Bible lessons",
    "Bible courses": "Bible courses",
    "Courses": "Courses",
    "Registration": "Registration",
    "Exit": "Exit",
    "Sign in": "Sign in",
    "Settings": "Settings",
    "Search": "Search",
    "Parallel places": "Parallel places",
    "Numbering of verses": "Numbering of verses",
    "Footnotes": "Footnotes",
    "Translation": "Translation",
    "Books": "Books",
    "Select a section": "Select a section",
    "Search with register": "Search with register",
    "Search whole word": "Search whole word",
    "New testament": "New testament",
    "Old testament": "Old testament",
    "The whole Bible": "The whole Bible",
    "Home": "Home",
    "Enter the text": "Enter the text",
    "To find": "To find",
    "Search taking into account the register": "Search taking into account the register",
    "Search for a whole word (as entered)": "Search for a whole word (as entered)",
    "In Ukrainian": "In Ukrainian",
    "In Russian": "In Russian",
    "In English": "In English",
    "Greetings": "Greetings",
    "Birthday": "Birthday",
    "Quotes": "Quotes",
    "Psalms": "Psalms",
    "Saturday": "Saturday",
    "Inspirations": "Inspirations",
    "Wedding": "Wedding",
    "Small": "Small",
    "Medium": "Medium",
    "Large": "Large",
    "Select": "Select",
    "Copy": "Copy",
    "Share": "Share",
    "Share viber": "Share viber",
    "Share telegram": "Share telegram",
    "Share whatsapp": "Share whatsapp",
    "Selected": "Selected",
    "Strong's Dictionary": "Strong's Dictionary",
    "Pronunciation": "Pronunciation",
    "Transliteration": "Transliteration",
    "Empty list": "Empty list",
    "End of the book": "End of the book",
    "Plan": "Plan",
    "Morning reading": "Morning reading",
    "Bible": "Bible",
    "Read": "Read",
    "Bible reading plan": "Bible reading plan",
    "Select translation": "Select translation",

    "Reading for today": "Reading for today",
    "More details": "More details",
    "Select book": "Select book",
    "More pictures": "More pictures",
    "Copied": "Copied",
    "Bible reading plans": "Bible reading plans",
    "Start a plan": "Start a plan",
    "Read the plan": "Read the plan",
    "Complete the plan": "Complete the plan",
    "Day": "Day",
    "Do you really want to delete your reading progress": "Do you really want to delete your reading progress",
    "Yes": "Yes",
    "Bible School": "Bible School",
    "No": "No",
    "Recited": "Recited",
    "Gospels and Epistles": "Gospels and Epistles",
    "The Pentateuch and the history of Israel": "The Pentateuch and the history of Israel",
    "Psalms and wisdom literature": "Psalms and wisdom literature",
    "Chronicles and prophets": "Chronicles and prophets",
    "Chronological Bible": "Chronological Bible",
    "Happy Birthday": "Happy Birthday",
    "Happy Baptism Day": "Happy Baptism Day",
    "With the birth of a daughter": "With the birth of a daughter",
    "With the birth of a son": "With the birth of a son",
    "Happy New Year": "Happy New Year",
    "To the best grandmother": "To the best grandmother",
    "The best mom": "The best mom",
    "The best father": "The best father",
    "The best grandfather": "The best grandfather",
    "Biblical": "Biblical",
    "Greetings tab": "Greetings",
    "Paul\'s message": "Paul\'s message",
    "Jacob, Peter, Ivan": "Jacob, Peter, Ivan",
    "Gospels": "Gospels",
    "Pentateuch": "Pentateuch",
    "Proverbs, Ecclesiastes": "Proverbs, Ecclesiastes",
    "Great prophets": "Great prophets",
    "Isaiah": "Isaiah",
    "Jeremiah": "Jeremiah",
    "Ezekiel": "Ezekiel",
    "Minor prophets": "Minor prophets",
    "Daniel": "Daniel",
    "Revelation": "Revelation",
    "Acts of the Apostles": "Acts of the Apostles",
    "Thematic": "Thematic",
    "Good morning": "Good morning",
    "Good evening": "Good evening",
    "Good night": "Good night"
}

export default dic;